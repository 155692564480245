import React from "react"
import ContactForm from '../components/contact'
import Layout from "../components/layout"

const ContactPage = (props) => {
  return(
    <Layout location={props.location}>
      <ContactForm />
    </Layout>
  )
}

export default ContactPage
